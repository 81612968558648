import { useLocation } from "react-router-dom";
import {useState} from 'react'
import axios from 'axios'
import { useNavigate } from 'react-router-dom';

export default function Uploader(){
    const navigate = useNavigate();
    const [start, setStart] = useState(false)
    const location = useLocation();
    const uploader = async (img) => {
        axios.post('https://app.idfoto.com.pl/api/uploader', JSON.stringify({
            'img': img
        })).then(function(response){
            console.log('uploader')
            console.log(response.data)
            navigate('/uploaded', {
                state:{
                    data: response.data
                }
            })
        }).catch(function(err){
            console.log(err.response.data)
        })
    }
    if(start == false){
        setStart(true)
        if(location.state.image.dataUrl != undefined){
            var reader = new FileReader();
            reader.readAsDataURL(location.state.image); 
            reader.onloadend = function() {
                var base64data = reader.result;                
                uploader(base64data)
            }
        }else{
            uploader(location.state.image)
        }

    }
    return (
        <div className='col-12 h-100 d-flex flex-wrap justify-content-center align-items-center' style={{position: 'fixed'}}>
            <img src={require('../img/load.gif')} />
        </div>
    )
}