import { useLocation } from 'react-router-dom'
import { useState } from 'react'
import axios from 'axios'
import Nawigacja from '../layout/menu'
import Footer from '../layout/footer'

export default function BlogSingle(){
    const [title, setTitle] = useState('')
    const [image, setImage] = useState('')
    const [entry, setEntry] = useState('')
    const [content, setContent] = useState('')
    const [pobrano, setPobrano] = useState(false)
    
    var location = useLocation()
    location = location.pathname.replace('/blog/', '')
    const pobierz = async (url) => {
        await axios.post('https://app.idfoto.com.pl/api/getSinglePost', JSON.stringify({
            url: url
        })).then(function(response){
            var q = response.data.post
            setTitle(q.title)
            setImage('https://app.idfoto.com.pl/'+q.image)
            setEntry(q.entry)
            setContent(q.content)
        }).catch(function(err){
            console.log(err.response.data)
        })
    }
    if(pobrano == false){
        setPobrano(true)
        pobierz(location)
    }


    return (
        <>
            <Nawigacja />
            <div className="container">
                <div className='row'>
                    <div className='col-12 p-5'>
                        <h1 className='articleTitle'>{title}</h1>
                        <img src={image} alt={title} className="col-12"/>
                        <div className='entryText' dangerouslySetInnerHTML={{__html: entry}} ></div>
                        <div className='contentText border-top pt-5' dangerouslySetInnerHTML={{__html: content}} ></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}