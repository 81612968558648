import { useLocation } from 'react-router-dom'
import {useState, useEffect} from 'react'
import axios from 'axios'
import Nawigacja from '../layout/menu'
import Footer from '../layout/footer'

export default function Offer(){
    const [title, setTitle] = useState('')
    const [image, setImage] = useState('')
    const [content, setContent] = useState('')
    const [pobrano, setPobrano] = useState(false)
    var location = useLocation()
    var url = location.pathname.replace('/oferta/', '')
    useEffect(() => {
        // execute on location change
        setPobrano(false)
    }, [location]);
    
    const pobierz = async (url) => {
        await axios.post('https://app.idfoto.com.pl/api/getPage', JSON.stringify({
            'url': url
        })).then(function(response){
            if(response.data == ''){
                window.location.href = '/'
            }
            var q = response.data
            setTitle(q.title)
            setImage('https://app.idfoto.com.pl/'+q.image)
            setContent(q.content)
        }).catch(function(err){
            console.log(err.response.data)
        })
    }
    if(pobrano == false){
        setPobrano(true)
        pobierz(url)
    }

    return (
        <>
            <Nawigacja />
            <div className="container">
                <div className="row p-5">
                    <article>
                        <img src={image} alt={title} className="col-12 my-5 mx-3"/>
                        <h1 className="articleTitle">{title}</h1>
                        <div className="articleContent" dangerouslySetInnerHTML={{__html: content}} />
                    </article>
                </div>
            </div>
            <Footer />
        </>
    )
}