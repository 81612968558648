import { useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom';
import Nawigacja from '../layout/menu'
import Footer from '../layout/footer'

export default function Blog(){
    const [posts, setPosts] = useState([])
    const [all, setAll] = useState(null)
    const [pobrano, setPobrano] = useState(false)
    const pobierz = async () => {
        await axios.post('https://app.idfoto.com.pl/api/getLastPosts', JSON.stringify({
                    limit: 9
                })).then(function(response){
                        setAll(response.data.all)
                        var q = response.data.last
                        var tab = []
                        for(let i=0;i<q.length;i++){
                            var img = 'https://app.idfoto.com.pl/'+q[i].image
                            var url = '/blog/'+q[i].url
                            tab.push(<div key={i} className="col-12 col-md-3 mx-1 blogMainEntry my-2">
                                <img src={img} className="col-12" alt={q[i].title}/>
                                <h4 className="howToTitle py-1 px-3">{q[i].title}</h4>
                                <div className="col-12 px-3" dangerouslySetInnerHTML={{__html: q[i].entry}} />
                                <Link to={url} className="btn btn-primary float-end">Czytaj dalej</Link>
                            </div>)
                        }
                        setPosts(tab)
                })
                    .catch(function(err){
                        console.log(err.response.data)
                    })
    }
    const getMoreFromBlog = async () => {
        await axios.post('https://app.idfoto.com.pl/api/getMoreFromBlog', JSON.stringify({
                    limit: 3,
                    offset: posts.length
                })).then(function(response){
                        setAll(response.data.all)
                        var q = response.data.last
                        
                        var tab = []
                        var tab1 = posts
                        tab1.forEach(function(value, index, array){
                            tab.push(value)
                        })
                        for(let i=0;i<q.length;i++){
                            var img = 'https://app.idfoto.com.pl/'+q[i].image
                            var url = '/blog/'+q[i].url
                            tab.push(<div key={posts.length+i} className="col-12 col-md-3 mx-1 blogMainEntry my-2">
                                <img src={img} className="col-12" alt={q[i].title}/>
                                <h4 className="howToTitle py-1 px-3">{q[i].title}</h4>
                                <div className="col-12 px-3" dangerouslySetInnerHTML={{__html: q[i].entry}} />
                                <Link to={url} className="btn btn-primary float-end">Czytaj dalej</Link>
                            </div>)
                        }
                        setPosts(tab)
                })
                    .catch(function(err){
                        console.log(err.response.data)
                    })
    }
    if(pobrano == false){
        setPobrano(true)
        pobierz()
    }
    var btnMoreBlog = null
    if(all != posts.length){
      var btnMoreBlog = <button className="btn btn-primary p-3 mt-5" onClick={()=>{getMoreFromBlog()}}>Pokaż więcej</button>
    }else{
        var btnMoreBlog = null
    }
    return (
        <>
            <Nawigacja />
                <div className="container">
                    <div className="row">
                        <h1 className="title articleTitle mt-5 pt-5">Aktualności</h1>
                        <div className="col-12 d-flex flex-wrap justify-content-center">
                            {posts}
                        </div>
                    </div>
                </div>
                <div className="col-12 d-flex flex-wrap justify-content-center mt-5">
                    {btnMoreBlog}
                </div>
            <Footer />
        </>
    )
}