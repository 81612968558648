import {useState} from 'react'
import Nawigacja from '../layout/menu'
import Footer from '../layout/footer'
import axios from 'axios'
import Swal from 'sweetalert2'

export default function Contact(){
    const [infoTitle, setInfoTitle] = useState(null)
    const [contentTitle, setContentTitle] = useState(null)
    const [imie, setImie] = useState('')
    const [telefon, setTelefon] = useState('')
    const [email, setEmail] = useState('')
    const [tresc, setTresc] = useState('')
    const post = async () => {
        if(imie != '' && telefon != '' && email != '' && tresc != ''){
            await axios.post('https://app.idfoto.com.pl/api/sendMsg', JSON.stringify({
                name: imie,
                phone: telefon,
                email: email,
                content: tresc
            }), {
                "Access-Control-Allow-Origin": "*"
            }).then(function(response){
                Swal.fire({
                    title: 'Dziękujemy za wiadomość!',
                    text: 'Odpowiemy na nią tak szybko, jak tylko będzie to możliwe!',
                    icon: 'success'
                })
                setImie('')
                setTelefon('')
                setEmail('')
                setTresc('')
            }).catch(function(err){
                console.log(err)
            })
        }
        if(imie == ''){
            Swal.fire({
                title: 'Ups...',
                text: 'Powiedz nam jak masz na imię',
                icon: 'error'
            })
        }
        if(telefon == ''){
            Swal.fire({
                title: 'Ups...',
                text: 'Miło byłoby wiedzieć do kogo zadzwonić w przypadku problemów',
                icon: 'error'
            })
        }
        if(email == ''){
            Swal.fire({
                title: 'Ups...',
                text: 'Powiedz na jaki adres e-mail mamy wysłać plik cyfrowy?',
                icon: 'error'
            })
        }
        if(email == ''){
            Swal.fire({
                title: 'Ups...',
                text: 'Gdzie mamy wysłać plik cyfrowy?',
                icon: 'error'
            })
        }
        if(tresc == ''){
            Swal.fire({
                title: 'Ups...',
                text: 'Zapomniałeś o treści wiadomości?',
                icon: 'error'
            })
        }
    }
    return (
        <>
            <Nawigacja />
                <div className="container py-5 d-flex flex-wrap justify-content-center">
                    <div className="col-8 col-md-6">
                        <label className="spanTitle d-flex ps-2"><span className="formLabel">Imię:</span></label>
                        <input type="text" value={imie} onChange={(txt)=>{
                            setImie(txt.target.value)
                        }} className="form-control mb-3" placeholder="Wprowadź imię..."/>
                        <div className="col-12 d-flex flex-wrap justify-content-between">
                            <div className="col-12 col-md-5">
                                <label className="spanTitle d-flex ps-2"><span className="formLabel">E-mail:</span></label>
                                <input type="text" value={email} onChange={(txt)=>{
                            setEmail(txt.target.value)
                        }}  className="form-control mb-3" placeholder="Wprowadź e-mail..."/>
                            </div>
                            <div className="col-12 col-md-5">
                                <label className="spanTitle d-flex ps-2"><span className="formLabel">Telefon:</span></label>
                                <input type="text" value={telefon} onChange={(txt)=>{
                            setTelefon(txt.target.value)
                        }}  className="form-control mb-3" placeholder="Wprowadź telefon..."/>
                            </div>
                        </div>
                        <label className="spanTitle d-flex ps-2"><span className="formLabel">Treść wiadomości:</span></label>
                        <textarea className="form-control mb-3" value={tresc} onChange={(txt)=>{
                            setTresc(txt.target.value)
                        }}  placeholder="Wprowadź treść wiadomości..."></textarea>
                        <input type="submit" className="btn btn-primary col-12" value="Wyślij wiadomość" onClick={()=>{post()}}/>
                    </div>
                </div>
            <Footer />
        </>
    )    
}