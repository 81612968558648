import { useLocation } from "react-router-dom"
import { useState } from "react"
import axios from "axios"
import Nawigacja from '../layout/menu'
import Footer from '../layout/footer'

export default function StatusZamowienia(){
    const [order, setOrder] = useState(null)
    const [status, setStatus] = useState('Sprawdzam')
    const location = useLocation()
    const sessionID = location.pathname.replace('/zamowienie/', '')
    const pobierz = async (sessionID) => {
        await axios.post('https://app.idfoto.com.pl/api/getOrder', JSON.stringify({
            sessionID: sessionID
        })).then(function(response){
            setOrder(response.data)
            setTimeout(()=>{
                setStatus(response.data.status)
            }, 500)
        }).catch(function(err){
            console.log(err)
        })
    }
    if(order == null){
        setOrder(false)
        pobierz(sessionID)
    }
    return (
        <>
            <Nawigacja />
                <div className="container">
                    <div className="row pt-5">
                        <div className="col-12 d-flex flex-wrap justify-content-center align-items-center pt-5">
                            <div className="col-12 col-md-8">
                                <h1 className="articleTitle text-center">Dziękujemy za zakup!</h1>
                                <p>Cieszymy się że wybrałeś nasze rozwiązanie. W przypadku jakichkolwiek pytań, zapraszamy do kontaktu, gdzie udzielimy odpowiedzi na wszelkie pytania i wątpliwości.</p>
                            </div>
                            <div className="col-12 col-md-4">
                                <p className="text-center">Status Twojej płatności:</p>
                                <h1 class="articleTitle text-center">{status}</h1>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>
    )
}