import Modal from 'react-modal';
import { useState } from 'react'
import Webcam from "react-webcam";
import { redirect } from "react-router-dom";
import ImageUploader from 'react-image-upload'
import {AiOutlineCloudUpload} from 'react-icons/ai'
import {TbCameraSelfie} from 'react-icons/tb'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom';

export default function UploaderModal(){
    const navigate = useNavigate();
    const generujOknoTworzenia = () => {
        setMakePhoto(<Webcam
            audio={false}
            screenshotFormat="image/jpeg"
            width={'100%'}
            height={800}
            videoConstraints={videoConstraints}
        >
            {({ getScreenshot }) => (
            <button
                onClick={() => {
                    const imageSrc = getScreenshot()
                    navigate('/uploader', {
                        state:{
                            image: imageSrc,
                        }
                    })
                }}
                className='col-12 mx-1 btn btn-dark'
            >
                Wykonaj zdjęcie
            </button>
            )}
        </Webcam>)
    }
    const [email, setEmail] = useState('')
    const [modalIsOpen, setIsOpen] = useState(false)
    const [makePhoto, setMakePhoto] = useState(<TbCameraSelfie className='bigIcon formIcon' onClick={()=>{
        Swal.fire({
            title: 'Uwaga!',
            text: 'Podczas wykonywania zdjęcia, patrz w soczewkę aparatu, jednocześnie dbając o to by zdjęcie wyszło wyraźne i było robione w jasnym pomieszczeniu.',
            icon: 'question'
        })
        generujOknoTworzenia()
    }}/>)
    const customStyles = {
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          borderColor: '#00a76f',
          width: '80%',
          height: '90%',
          display:'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center'
        },
      };
      const videoConstraints = {
        width: 720,
        height: 1920
      };
      const getImageFileObject = (imageFile) => {
        console.log(imageFile)
        var reader = new FileReader();
        reader.readAsDataURL(imageFile.file)
        reader.onloadend = function () {
            var base64data = reader.result;
            navigate('/uploader', {
                state:{
                    image: base64data,
                }
            })
        }
      }
    
      const runAfterImageDelete = (file) => {
        console.log({ file })
      }
      const iconUpload = <AiOutlineCloudUpload className='bigIcon formIcon' style={{width: '100%'}}/>
    return (
        <>
            <button className="col-12 border-radius btn btn-primary mt-5 fs-3" onClick={()=>{setIsOpen(true)}}>Wgraj zdjęcie</button>
            <Modal
                isOpen={modalIsOpen}
                style={customStyles}
                ariaHideApp={false}
                contentLabel="Generuj zdjęcie do dokumentu"
            >
                <button className="closeModalBtn" onClick={()=>{setIsOpen(false)}}>X</button>
                <h1 className="articleTitle">Na początek, potrzebujemy Twojego zdjęcia:</h1>
                <div className='col-12 d-flex flex-wrap justify-content-center'>
                    
                    <div className='iconBox col-12 col-md-6 d-flex flex-wrap flex-column justify-content-center align-items-center'>
                        <label className='fs-2 titleMainLight'>Prześlij nam swoje zdjęcie</label>
                        <ImageUploader
                            onFileAdded={(img) => getImageFileObject(img)}
                            onFileRemoved={(img) => runAfterImageDelete(img)}
                            uploadIcon={iconUpload}
                        />
                    </div>
                </div>
            </Modal>
        </>
    )
}