import React from 'react'
import logo from './logo.svg'
import MainPage from './routes/mainPage'
import Contact from './routes/contact'
import Offer from './routes/offer'
import Blog from './routes/blog'
import BlogSingle from './routes/blogSingle'
import Uploader from './routes/uploader'
import Uploaded from './routes/uploaded'
import UploaderFinal from './routes/uploaderFinal'
import StatusZamowienia from './routes/statusZamowienia'
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom"
import './App.css'


function App() {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <MainPage />,
    },
    {
      path: "/hello",
      element: <div>Helo world</div>,
    },
    {
      path: "/zdjecie-do-legitymacji-on-line",
      element: <Contact />,
    },
    {
      path: "/oferta/:url",
      element: <Offer />
    },
    {
      path: "/blog",
      element: <Blog />
    },
    {
      path: "/blog/:url",
      element: <BlogSingle />
    },
    {
      path: "/uploader",
      element: <Uploader />
    },
    {
      path: "/uploaded",
      element: <Uploaded />
    },
    {
      path: "/uploaderFinal",
      element: <UploaderFinal />
    },
    {
      path: "/zamowienie/:url",
      element: <StatusZamowienia />
    }
  ]);
  
  return (
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

export default App;
