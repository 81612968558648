import { useLocation } from "react-router-dom";
import Nawigacja from '../layout/menu'
import Footer from '../layout/footer'
import { useState } from "react";
import axios from "axios";
import Swal from "sweetalert2";
import {GiShoppingCart} from 'react-icons/gi'

export default function UploaderFinal(){
    const location = useLocation()
    const [b64, setB64] = useState('')
    const [image, setImage] = useState('')
    const [wgrano, setWgrano] = useState(false)
    const [kwotaDruk, setKwotaDruk] = useState(20)
    const [kwotaWysylka, setKwotaWysylka] = useState(10)
    const [kwotaZamowienie, setKwotaZamowienie] = useState(5)
    const [kwotaKoncowa, setKwotaKoncowa] = useState(5)
    const [imie, setImie] = useState('')
    const [nazwisko, setNazwisko] = useState('')
    const [ulica, setUlica] = useState('')
    const [kod, setKod] = useState('')
    const [miejscowosc, setMiejscowosc] = useState('')
    const [email, setEmail] = useState('')
    const [telefon, setTelefon] = useState('')
    if(b64 == ''){
        setB64(location.state.image)
    }
    const upload = async (img) => {
        await axios.post('https://app.idfoto.com.pl/api/uploaderFinal', JSON.stringify({
            img: img
        })).then(function(response){
            setImage(response.data.img)
            setKwotaDruk((response.data.cennik.wydruk * 100)/100)
            setKwotaWysylka((response.data.cennik.dostawa * 100)/100)
            setKwotaZamowienie((response.data.cennik.cyfrowe * 100)/100)
            setKwotaKoncowa((response.data.cennik.cyfrowe * 100)/100)
        }).catch(function(err){
            console.log(err.response.data)
        })
    }
    const makePayment = async () => {
        if(email == ''){
            Swal.fire({
                title: 'Brak adresu e-mail!',
                text: 'Musimy wiedzieć na jaki adres e-mail wysłać gotowy plik!',
                icon: 'error'
            })
        }
        if(telefon == '' || telefon.length < 9){
            Swal.fire({
                title: 'Brak numeru telefonu!',
                text: 'Byłoby super móc zadzwonić, gdyby zaszła taka konieczność!',
                icon: 'error'
            })
        }
        if(email != '' && telefon != ''){
            await axios.post('https://app.idfoto.com.pl/api/makeOrder', JSON.stringify({
                imie: imie,
                nazwisko: nazwisko,
                ulica: ulica,
                kod: kod,
                miejscowosc: miejscowosc,
                telefon: telefon,
                email: email,
                image: image
            })).then(function(response){
                window.location.href=response.data.redirect
            }).catch(function(err){
                console.log(err)
            })
        }
    }
    const aktualizacjaCeny = () => {
        setTimeout(()=>{
            if(imie != '' && nazwisko != '' && ulica != '' && kod != '' && miejscowosc != ''){
                setKwotaKoncowa(kwotaZamowienie+kwotaDruk+kwotaWysylka)
            }else{
                setKwotaKoncowa(kwotaZamowienie)
            }
        }, 200)
    }
    if(wgrano == false){
        setWgrano(true)
        upload(location.state.image)
    }
    if(wgrano == false || image == ''){
        var formOrUpload = <div className="col-12 col-md-4 d-flex flex-wrap flex-column justify-content-center align-items-center">
                                <img src={require('../img/load.gif')} alt="Wgrywanie"/>
                                <h1 className="articleTitle text-center mt-5">Daj nam chwilę!</h1>
                                <p className="text-center">Wgrywamy teraz Twoje zdjęcie na nasz serwer, by móc dokonczyć zamówienie.</p>
                            </div>
    }else{
        var formOrUpload = <div className="col-12 d-flex flex-wrap justify-content-center align-items-center pt-5">
            <h3 className="price col-12">Koszt zamówienia: <span className="formLabel">{kwotaKoncowa.toFixed(2)}zł</span></h3>
            <div className="col-12 col-md-8 d-flex flex-wrap justify-content-center px-2">
                <div className="col-12 col-md-6 px-2 my-2">
                    <label className="spanTitle d-flex ps-2"><span className="formLabel">E-mail *:</span></label>
                    <input type="text" value={email} onChange={txt => setEmail(txt.target.value)} className="form-control" placeholder="Podaj e-mail..."/>
                </div>
                <div className="col-12 col-md-6 px-2 my-2">
                    <label className="spanTitle d-flex ps-2"><span className="formLabel">Telefon:</span></label>
                    <input type="text" value={telefon} onChange={txt => setTelefon(txt.target.value)} className="form-control" placeholder="Podaj telefon..."/>
                </div>
                <button className="col-12 btn btn-primary mt-5" onClick={()=>{
                    Swal.fire({
                        title: "Uwaga!",
                        html: '<p>Przechodząc dalej, oświadczasz iż akceptujesz warunki <a href="/oferta/rodo" target="_blank" class="linkSwal">polityki prywatności</a> oraz <a href="/oferta/regulamin" target="_blank" class="linkSwal">regulaminu</a></p>',
                        showCancelButton: true,
                        cancelButtonText: 'Odrzuć',
                        confirmButtonText: 'Akceptuje'
                    }).then(function(result){
                        if(result.isConfirmed){
                            makePayment()
                        }
                    })
                }}>Zamawiam tylko wersję elektroniczną</button>
                <div className="col-12 border-bottom my-5"></div>
                <div className="infoZamowienie">
                    <p>Jeśli chcesz, możemy dla Ciebie dodatkowo przygotować zdjęcia w wersji drukowanej. Dzięki temu otrzymasz na maila wersję cyfrową fotografii a wersję papierową wyślemy pocztą. Prosimy tylko wypełniż poniższą część formularza.</p>
                    <p>W przypadku wersji drukowanej, doliczymy koszt <strong>{kwotaDruk.toFixed(2)}zł</strong> oraz <strong>{kwotaWysylka.toFixed(2)}zł</strong> za wysyłkę.</p>
                </div>
                <div className="col-12 col-md-6 px-2 my-2">
                    <label className="spanTitle d-flex ps-2"><span className="formLabel">Imię:</span></label>
                    <input type="text" value={imie} onChange={(txt) => {
                        setImie(txt.target.value)
                        aktualizacjaCeny()
                    }} className="form-control" placeholder="Wprowadź imię..."/>
                </div>
                <div className="col-12 col-md-6 px-2 my-2">
                    <label className="spanTitle d-flex ps-2"><span className="formLabel">Nazwisko:</span></label>
                    <input type="text" value={nazwisko} onChange={(txt) => {
                            setNazwisko(txt.target.value)
                            aktualizacjaCeny()
                        }} className="form-control" placeholder="Wprowadź nazwisko..."/>
                </div>
                <div className="col-12 col-md-5 px-2 my-2">
                    <label className="spanTitle d-flex ps-2"><span className="formLabel">Nazwa ulicy i nr:</span></label>
                    <input type="text" value={ulica} onChange={(txt) => {
                                setUlica(txt.target.value)
                                aktualizacjaCeny()
                            }} className="form-control" placeholder="Wprowadź nazwę ulicy i nr..."/>
                </div>
                <div className="col-12 col-md-4 px-2 my-2">
                    <label className="spanTitle d-flex ps-2"><span className="formLabel">Poczta:</span></label>
                    <input type="text" value={kod} onChange={(txt) => {
                            setKod(txt.target.value)
                            aktualizacjaCeny()
                        }} className="form-control" placeholder="00-000"/>
                </div>
                <div className="col-12 col-md-3 px-2 my-2">
                    <label className="spanTitle d-flex ps-2"><span className="formLabel">Miejscowość:</span></label>
                    <input type="text" value={miejscowosc} onChange={(txt) => {
                            setMiejscowosc(txt.target.value)
                            aktualizacjaCeny()
                        }} className="form-control" placeholder="Podaj miejscowość..."/>
                </div>
                <button className="col-12 btn btn-primary mt-5" onClick={()=>{
                    Swal.fire({
                        title: "Uwaga!",
                        html: '<p>Przechodząc dalej, oświadczasz iż akceptujesz warunki <a href="/oferta/rodo" target="_blank" class="linkSwal">polityki prywatności</a> oraz <a href="/oferta/regulamin" target="_blank" class="linkSwal">regulaminu</a></p>',
                        showCancelButton: true,
                        cancelButtonText: 'Odrzuć',
                        confirmButtonText: 'Akceptuje'
                    }).then(function(result){
                        if(result.isConfirmed){
                            makePayment()
                        }
                    })
                }}>Zamawiam i płacę</button>
            </div>
            <div className="col-12 col-md-4">
            <label className="spanTitle d-flex ps-2"><span className="formLabel">Wgrywana fotografia:</span></label>
                <img src={image} className="col-12" alt="#"/>
            </div>
        </div>
    }
    return <>
        <Nawigacja />
            <div className="container">
                <div className="row">
                    <div className="col-12 d-flex flex-wrap justify-content-center">
                        {formOrUpload}
                    </div>
                </div>
            </div>
            <div className="podsumowanieKosztow d-flex flex-wrap justify-content-center align-items-center">
                <GiShoppingCart className="fs-2"/>
                <p className="pb-0 mb-0">{kwotaKoncowa.toFixed(2)}zł</p>
            </div>
        <Footer />
    </>
}