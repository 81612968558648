import { useLocation } from "react-router-dom";
import Nawigacja from '../layout/menu'
import Footer from '../layout/footer'
import Draggable from 'react-draggable';
import {useState, createRef} from 'react'
import Swal from "sweetalert2"
import {FiRotateCw} from 'react-icons/fi'
import ReactPanZoom from 'react-image-pan-zoom-rotate'
import ModalZamowienie from '../layout/modalZamowienie'
import { useNavigate } from "react-router-dom"
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';
import axios from "axios";


export default function Uploaded(){
        
    const navigation = useNavigate()
    const location = useLocation();
    const [imgOpacity, setImgOpacity] = useState(1)
    const [scale, setScale] = useState(100)
    const [rotate, setRotate] = useState(0)
    const [porada1, setPorada1] = useState(false)
    const [image, setImage] = useState('https://app.idfoto.com.pl/u/img/px.png')
    const [finalImage, setFinalImage] = useState(false)
    const [cropped, setCropped] = useState(null)
    const [pobrano, setPobrano] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth*0.8)
    const [screenHeight, setScreenHeight] = useState(window.innerHeight*0.8)
    const [time, setTime] = useState(15)
    const [displayCounter, setDisplayCounter] = useState('block')
    const checkStatus = async (task_id) => {
        await axios.post('https://app.idfoto.com.pl/api/refreshPhoto', JSON.stringify({
            'task_id' : task_id
        })).then(function(response){
            console.log(response.data)
            if(response.data.image != undefined){
                setImage(response.data.image)
                setFinalImage(true)
                setDisplayCounter('none')
            }
        }).catch(function(err){
            console.log(err)
        })
    }
    if(pobrano == false){
        setPobrano(true)
        if(location.state.data.status == 200){
            Swal.fire({
                title: 'Zaczekaj chwilę!',
                text: 'W ciągu kilkudziesięciu sekund, Twoje zdjęcie zostanie pozbawione tła. Ustaw wtedy swoje zdjęcie tak by oczy i broda znalazły się możliwie najbliżej zielonych pól które pojawią się po najechaniu na zdjęcie.',
                icon: 'question'
            })
        }
    }
    if(finalImage == false){
        setTimeout(()=>{
            setTime(time - 1)
        }, 1000)
    }
    if(time < 1){
        checkStatus(location.state.data.data.task_id)
        setTime(15)
    }
    /*if(cropped != null && cropp.crops != undefined && image == ''){
        setImage(cropp.crops[cropp.crops.length - 1].url)
    }else{

    }*/
    return (
        <>
            <Nawigacja />
                <div className="container">
                    <div className="row">
                        <div className="col-12 p-5 d-flex flex-wrap justify-content-center align-items-center">
                            <h1 className="articleTitle">Ustaw zdjęcie tak, by Twoja sylwetka pokrywała się ze wskazaną linią.</h1>
                            <label className="col-12 text-center titleMainLight" style={{display: displayCounter}}>Sprawdzimy ponownie czy Twoje zdjęcie bez tła jest już gotowe za <strong>{time}</strong> sekund.</label>
                            <div style={{width: screenWidth, height: screenHeight}} className='d-flex flex-wrap justify-content-center'>
                            <img onLoad={(q)=>{
                                setScreenWidth(q.target.clientWidth)
                                setScreenHeight(q.target.clientHeight)
                                var box = document.getElementById('scanner')
                                box = box.getElementsByTagName('div')[0]
                                box = box.getElementsByTagName('div')[0]
                                box.style.right = 'unset'
                                box.style.top = 'unset'
                            }} id="screen" src={require('../img/makieta.png')} style={{maxWidth: '80%', maxHeight: '80%', border: 'solid 0.5px #ccc', position: 'absolute', marginTop:'50px'}}/>
                            <div className="p-5 d-flex flex-wrap justify-content-center clearfix">
                                        <img src={require('../img/useScroll.png')} alt="scroll" className="useScrollInfo"/>
                                    <div
                                        style={{
                                            width: screenWidth+'px',
                                            height: screenHeight+'px',
                                            overflow: "hidden"
                                        }}
                                        
                                        id="scanner"
                                    >
                                       <ReactPanZoom
                                            alt="Ustaw odpowiednio fotografię"
                                            image={image}
                                            width={screenWidth}
                                            height={screenHeight}
                                        />
                                    </div>
                                    <button className="col-12 mt-3 btn btn-dark" onClick={()=>{
                                        var container = document.querySelector(".pan-container")
                                        var img = container.getElementsByTagName('img')[0]
                                        var node = document.getElementById('scanner');
                                        htmlToImage.toPng(node)
                                        .then(function (dataUrl) {
                                            var img = new Image();
                                            img.src = dataUrl;
                                            navigation('/uploaderFinal', {
                                                'state': {
                                                    'image': dataUrl
                                                }
                                            })
                                        })
                                        .catch(function (error) {
                                            console.error('oops, something went wrong!', error);
                                        });
                                
                                        /*navigation('/zamowienie', {
                                            'state': {
                                                'image': image
                                            }
                                        })*/
                                    }}>Przejdź do zamówienia</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>
    )
}