import Nawigacja from '../layout/menu';
import Footer from '../layout/footer'
import Swal from 'sweetalert2'
import {useState} from 'react'
import UploaderModal from './uploaderModal'
import axios from 'axios'
import { Link } from 'react-router-dom';

export default function MainPage() {
    const [blog, setBlog] = useState([])
    const [all, setAll] = useState(0)
    const [pobrano, setPobrano] = useState(false)
    const pobierz = async () => {
      await axios.post('https://app.idfoto.com.pl/api/getLastFromBlog')
              .then(function(response){
                var q = response.data.last
                var tab = []
                for(let i=0;i<q.length;i++){
                  var img = 'https://app.idfoto.com.pl/'+q[i].image
                  var url = '/blog/'+q[i].url
                  tab.push(<div key={i} className="col-12 col-md-3 blogMainEntry">
                    <img src={img} className="col-12" alt={q[i].title}/>
                    <h4 className="howToTitle py-1 px-3">{q[i].title}</h4>
                    <div className="col-12 px-3" dangerouslySetInnerHTML={{__html: q[i].entry}} />
                    <Link to="" className="btn btn-primary float-end">Czytaj dalej</Link>
                  </div>)
                }
                setBlog(tab)
                setAll(response.data.all)
              })
              .catch(function(err){
                console.log(err)
              })
    }
    if(pobrano == false){
      setPobrano(true)
      pobierz()
    }
    var btnMoreBlog = null
    if(all > 3 && pobrano == true){
      var btnMoreBlog = <Link to="/blog" className="btn btn-primary p-3 mt-5">Pokaż więcej</Link>
    }
    return (
      <>
        <Nawigacja />
          <div className="container d-flex flex-wrap justify-content-center">
            <div className="col-12 col-md-6 py-5">
              <img src={require('../img/mainConvert.png')} className="col-12"/>
              <div className="col-12 d-flex flex-wrap justify-content-center">
                <div className="col-6 px-5">
                  <label className="contentLabel">
                    Doświadczenie
                  </label>
                  <p>Nasza firma, stawiając na nowoczesność, może pochwalić się jednocześnie wieloletnią oraz pokoleniową tradycję.</p>
                </div>
                <div className="col-6 px-5">
                    <label className="contentLabel">
                      Gwarancja
                    </label>
                    <p>Od lat świadczymy usługi fotograficzne z różnego zakresu, ciesząc się nieposzlakowaną opinią. Znamy się na tym co robimy i gwarantujemy niezawodność.</p>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 p-5 d-flex flex-wrap justify-content-center align-items-center">
              <div className="col-12 col-md-10">
                <label className="titleMainHuge">
                  Zdjęcie do mLegitymacji - wykonaj samodzielnie!
                </label>
                <label className="titleMainLight">
                  Wgraj swoje zdjęcie i wygeneruj gotowy plik cyfrowy który otrzymasz na maila.
                </label>
                <UploaderModal />
              </div>
            </div>
          </div>
          <div className="col-12 d-flex flex-wrap justify-content-center">
            <h1 className="mainTitle text-center col-12 mb-2">Ale jak to działa?</h1>
            <div className="col-12 col-md-2 p-5">
              <img src={require('../img/selfie.png')} alt="Zdjęcie do mLegitymacji" className="col-12"/>
              <h3 className="howToTitle text-center mt-5">Zrób i wgraj zdjęcie</h3>
              <p className="text-center">Wykonaj swoje zdjęcie, przesyłając je przez formularz. Resztę wykonamy my!</p>
            </div>
            <div className="col-12 col-md-2 p-5">
              <img src={require('../img/chip.png')} alt="Zdjęcie do mLegitymacji" className="col-12"/>
              <h3 className="howToTitle text-center mt-5">Sztuczna inteligencja</h3>
              <p className="text-center">To krok w którym sztuczna inteligencja przygotuje Twoje zdjęcie do odpowiedniego formatu i usunie tło!</p>
            </div>
            <div className="col-12 col-md-2 p-5">
              <img src={require('../img/verified.png')} alt="Zdjęcie do mLegitymacji" className="col-12"/>
              <h3 className="howToTitle text-center mt-5">Weryfikacja</h3>
              <p className="text-center">Jeden z naszych pracowników zweryfikuje to czy fotografia jest gotowa, czy wymaga jeszcze ręcznych dociągnięć!</p>
            </div>
            <div className="col-12 col-md-2 p-5">
              <img src={require('../img/photo-album.png')} alt="Zdjęcie do mLegitymacji" className="col-12"/>
              <h3 className="howToTitle text-center mt-5">Gotowe!</h3>
              <p className="text-center">Otrzymasz na maila wersję cyfrową fotografii oraz jeśli zechcesz, możemy wydrukować i wysłać fotografie wydrukowane!</p>
            </div>
          </div>
          <div className="col-12 d-flex flex-wrap justify-content-center pt-5">
            {blog}
            {btnMoreBlog}
          </div>
        <Footer/>
      </>
    );
  }
  