import axios from 'axios';
import {useState} from 'react'
import { useLocation } from 'react-router-dom'
import { Link } from 'react-router-dom';

export default function Nawigacja(){
    const [getPages, setGetPages] = useState(false)
    const [naviPage, setNaviPage] = useState(null)
    const [mainActive, setMainActive] = useState('')
    const [offerActive, setOfferActive] = useState('')
    const [contactActive, setContactActive] = useState('')
    const location = useLocation();
    const pobierz = async () => {
        await axios.get('https://app.idfoto.com.pl/api/getPages')
                .then(function(response){
                    var q = response.data
                    var tab = []
                    for(let i=0;i<q.length;i++){
                        var url = '/oferta/'+q[i].url
                        if(q[i].title != 'Polityka prywatności' && q[i].title != 'Regulamin'){
                            tab.push(<li key={i}>
                                <Link to={url}>{q[i].title}</Link>
                            </li>)
                        }
                    }
                    setNaviPage(tab)
                })
                .catch(function(err){
                    console.log(err.response.data)
                })
    }
    if(getPages == false){
        setGetPages(true)
        pobierz()
    }
    if(mainActive == '' && offerActive == '' && contactActive == ''){
        var offerTabUrl = location.pathname.split('/')
        if(location.pathname == '/zdjecie-do-legitymacji-on-line'){
            setContactActive('active')
        }
        if(location.pathname == '/'){
            setMainActive('active')
        }
        if(location.pathname == '/oferta' || offerTabUrl[1] == 'oferta'){
            setOfferActive('active')
        }
    }
    return (
        <div className="bgCream">
            <div className="container">
                <div className="row d-flex flex-wrap justify-content-center border-bottom">
                    <Link to="/" className="col-8 col-md-3 col-lg-2 py-3">
                        <img src={require('../img/logo.jpg')} alt="IDFOTO" className="col-12"/>
                    </Link>
                    <ul className="navi d-none d-md-flex col-md-9 col-lg-10 justify-content-end align-items-center">
                        <li>
                            <Link to="/" className={mainActive}>Główna</Link>
                        </li>
                        <li className="haveSubmenu">
                            <Link to="#" className={offerActive}>Oferta</Link>
                            <ul className="submenu flex-wrap flex-column" style={{display:'none'}}>
                                {naviPage}
                            </ul>
                        </li>
                        <li>
                            <Link to="/zdjecie-do-legitymacji-on-line" className={contactActive}>Kontakt</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    )
}