import { Link } from 'react-router-dom';
import { useState } from 'react'
import axios from 'axios'

export default function Footer(){
    const [getPages, setGetPages] = useState(false)
    const [naviPage, setNaviPage] = useState(null)
    const pobierz = async () => {
        await axios.get('https://app.idfoto.com.pl/api/getPages')
                .then(function(response){
                    var q = response.data
                    var tab = []
                    for(let i=0;i<q.length;i++){
                        var url = '/oferta/'+q[i].url
                        if(q[i].title != 'Polityka prywatności' && q[i].title != 'Regulamin'){
                            tab.push(<li key={i}>
                                <Link to={url}>{q[i].title}</Link>
                            </li>)
                        }
                    }
                    setNaviPage(tab)
                })
                .catch(function(err){
                    console.log(err.response.data)
                })
    }
    if(getPages == false){
        setGetPages(true)
        pobierz()
    }
    return (
        <footer className="border-top pt-5 px-5 d-flex flex-wrap justify-content-center mt-5">
            <div className="container d-flex flex-wrap justify-content-center">
                <div className="col-12 col-md-3 px-2">
                    <label className="footerLabel">IDfoto.com.pl:</label>
                    <p>Lipowa 8d<br />59-400 Jawor</p>
                    <p>NIP: 6951337541</p>
                </div>
                <div className="col-12 col-md-3 px-2">
                    <label className="footerLabel">Dlaczego warto:</label>
                    <p>Wykonanie zdjęcia do dokumentów on-line to już nie przyszłość. U nas to teraźniejszość! Już dziś możesz wykonać swoje zdjęcie w domowym zaciszu, szybko, sprawnie i mieć zdjęcie w ciągu nawet kilkunastu minut.</p>
                </div>
                <div className="col-12 col-md-3 px-2">
                    <label className="footerLabel">Ważne:</label>
                    <ul>
                        <li><Link to="/oferta/rodo">Polityka prywatności</Link></li>
                        <li className="pb-5"><Link to="/oferta/regulamin" className="pb-3 border-bottom">Regulamin</Link></li>
                        {naviPage}
                    </ul>
                </div>
                <div className="col-12 col-md-3 px-2">
                    <label className="footerLabel">Kontakt:</label>
                    <p>
                        Telefon:<br /> <Link to="tel:608181336" className="telLink">608 181 336</Link><br /><br />
                        Mail: <br /><Link to="mailto:kontakt@idfoto.com.pl" className="telLink">kontakt@idfoto.com.pl</Link>
                    </p>
                </div>
            </div>
        </footer>
    )
}